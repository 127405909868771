/* src/styles.css */
@import 'tailwindcss/base';

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 20px;
    /* border: 3px solid var(--primary); */
}



@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
    font-family: 'Bahnschrift', sans-serif;
    src: url('assets/fonts/BAHNSCHRIFT.TTF');
}

/* :root {
    --swiper-theme-color: #FF6600;
    --swiper-navigation-size: 30px;
} */

body {
    font-family: 'Bahnschrift';
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}